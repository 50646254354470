<template>
  <div class="container">
    <div class="scan" v-show=scanning></div>
    <Map v-bind:player=playerData @changePosition="changePosition($event)" ></Map>
    <Panel v-bind:player=playerData v-bind:playerName=playerName v-bind:inGame=inGame v-bind:position=position @exploreCell="exploreCell($event)"></Panel>
    <ItemGained ref="itemGained"></ItemGained>
    <StoryLine ref="storyLine"></StoryLine>
  </div>
</template>

<script>
// @ is an alias to /src
import Panel from "@/components/Panel";
import Player from "@/obj/Player";
import Position from "@/obj/Position";
import Map from "@/components/Map";
import ItemGained from "@/components/ItemGained";
import Explorer from "@/explorer";
import StoryLine from "@/components/StoryLine";

export default {
  name: 'Home',
  components: {
    StoryLine, Panel, Map, ItemGained
  },
  methods: {
    changePosition(position) {
      this.position = position;
    },
    exploreCell(cellId) {

        var lastExplore = localStorage.getItem("explored");
        var currentTime = Math.floor(Date.now() / 1000);
        if (lastExplore < currentTime - 400) {
          this.scanning = true;
          window.setTimeout(() => {
                Explorer.explore(cellId, this.handleExplorationResult); },
              2500)
        }


    },
    handleExplorationResult(content) {
      this.scanning = false;
      if (content.ok) {
        var explorationContains = content.content;
        var currentTime = Math.floor(Date.now() / 1000);

        switch (explorationContains.contains) {
          case "energy":
            this.playerData.changeEnergy(explorationContains.amount);
            if (explorationContains.amount > 0) {
              this.$refs.itemGained.item = "⚡";
              this.$refs.itemGained.count = explorationContains.amount;
              this.$refs.itemGained.showGain();
            }
            localStorage.setItem("explored", currentTime);
            break;
          case "icecream":
            this.playerData.changeIcecream(explorationContains.amount);
            if (explorationContains.amount > 0) {
              this.$refs.itemGained.item = "🍦";
              this.$refs.itemGained.count = explorationContains.amount;
              this.$refs.itemGained.showGain();
            }
            localStorage.setItem("explored", currentTime);
            break;
          case "none":
            localStorage.setItem("explored", currentTime);
            break;
          case "story":
            if (explorationContains.lines!=null) {
              this.$refs.storyLine.lines = explorationContains.lines;
              this.$refs.storyLine.showLines();
            }
            break;
          case "game":
            var gameSeed = explorationContains.seed;
            localStorage.setItem("explored", currentTime);
            this.$router.push({path: `/game/${gameSeed}` })
            break;
          default:
            console.log("unknown content");
        }
      }
    }

  },
  data() {
    return {
      playerData: Player,
      playerName: '',
      inGame: false,
      position: Position,
      showGain: false,
      scanning: false
    }
  },
  mounted() {
    if (localStorage.name) {
      this.playerName = localStorage.name;
    }
  },
  watch: {
    playerName(newName) {
      localStorage.name = newName;
    },

  }
}
</script>

<style lang="scss">

.scan
{
  width:100%;
  height:2px;
  background-color: rgba(72, 72, 185, 0.76);
  position:absolute;
  z-index:2;
  -moz-animation: scan 2s infinite;
  -webkit-animation: scan 2s infinite;
  animation: scan 2s infinite;
  -webkit-animation-direction: alternate-reverse;
  box-shadow:0 0 30px rgba(255, 102, 156, 0.5);


}

@-webkit-keyframes scan {
  0%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100vh);
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s
}
.fade-enter, .fade-leave-to {
  opacity: 0
}
</style>