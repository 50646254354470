<template>
  <div class="container" v-if="!faq">
    <div class="card">
      <h1 class="neon">&#128123;</h1>
      <h2 class="neon">Kto si?</h2>
      <input :disabled="validating || error" v-model="authcode" type="text" name="login" class="field" placeholder="Zadaj kód hráča" v-on:keypress="enter" :class="{'shake':error}">
      <button :disabled="(authcode.length < 9) || (validating) || (error)" class="btn" v-on:click="login">Vstúpiť</button>
    </div>
    <p class="center"><a v-on:click="faq=true" class="link">Často kladené otázky</a></p>
  </div>
  <div class="container ghosttext scroll" v-if="faq">
    <h2>Často kladené otázky</h2>
    <h3>Prečo sa v hre nič nedeje?</h3>
    <ul><li>Ako hovorí príslovie starých duší: večer múdrejšie večerať. Duchovia si počas dňa užívajú zaslúžený odpočinok a po občianskom súmraku sa začínajú diať veci.</li></ul>
    <h3>Ako získam Kód hráča?</h3>
    <ul><li>Odporúčané: Použiješ svoj "ID kód" z kešky Zmrzlinový duch (<a class="link biglink" href="https://coord.info/GC6ERM7" target="_blank">GC6ERM7</a>). V nej nájdeš pokyny, ako získať ID kód aj dnes. Pri využití ID kódu ti budem vedieť v prípade problémov pomôcť a taktiež neprídeš o svoj drahocenný inventár.</li>
      <li>Vytvoríš si <a class="link biglink" v-on:click="goCreate">dočasný kód</a> bez overenia kešerskej identity. Môžeš sa hrať, ale údaje budú po uplynutí 2 týždňov zmazané a ako neoverenému kešerovi ti nemôžem poskytnúť žiadnu podporu. Zmizne aj celý progres v hre. Navždy.</li></ul>
    <h3>Prečo mi po čase zmizne časť inventára?</h3>
    <ul><li>Duchovia nemajú dobré termoizolačné vlastnosti, a preto sa v chladnom prostredí stráca energia, v teplom sa topí zmrzlina. Posmrtný život je ťažký.</li></ul>
    <p>&nbsp;</p>
    <p class="center"><a v-on:click="faq=false" class="link">Späť na prihlasovaciu obrazovku</a></p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </div>
  <canvas class="background"></canvas>
</template>

<script>
import Particles from 'particlesjs';
import auth from "../auth/";
import router from "../router";

export default {
  name: "Login",
  data() {
    return {
      authcode: '',
      validating: false,
      error: false,
      particles: null,
      faq: false
    }
  },
  mounted() {
    this.particles = Particles.init({
      selector: ".background",
      color: "#06001D",
      speed: 0.02,
      maxParticles: 100,
      connectParticles: false,
      sizeVariations: 10
    })
  },
  methods: {
    login: function() {
      this.error = false;
      this.validating = true;
      auth.loginViaApi(this.authcode, this.feedback);
    },
    goCreate: function() {
      router.push('/create');
    },
    feedback: function(state) {
      this.validating = false;
      if (state.logged === true) {
        router.push("/");
      } else {
        this.error = true;
        setTimeout(()=> {
          this.error = false;
          this.authcode = "";
        }, 820);
      }
    },
    enter: function(e) {
      this.error = false;
      if ((this.authcode.length > 8) && !(this.validating) && (e.keyCode === 13)) {
        this.login();
      }
    }
  },
  beforeUnmount() {
    if (this.particles !== null)
      this.particles.destroy();
  }
}
</script>

<style lang="scss" scoped>


.background {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: -1;
}


.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  color: #F90000!important;
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.center {
  text-align: center;
}

input.field {
  background: $color_dark;
  color: white;
  border: 5px solid $color_dark;
  font-family: $font_family_head;
  text-transform: uppercase;
  margin-top: 2em;
  text-align: center;
}

h2 {
  font-size:2em;
}

h3 { color: #0088F8; }

h1 {
  font-size: 666%;
  margin: 0;
  margin-top:-0.7em;
  width: 100%;
  display:block;
}

.ghosttext {
  color: ghostwhite;
}
.btn {
  display: block;
  margin: 0 auto;
  color: lightcyan;
  padding: 10px 20px;
  margin-top: 1em;
  font-family: $font_family_head;
  text-transform: uppercase;
  cursor: pointer;
  background: #0A012D;
  background-image: -webkit-linear-gradient(top, #0A012D, #0F0146);
  background-image: -moz-linear-gradient(top, #0A012D, #0F0146);
  background-image: -ms-linear-gradient(top, #0A012D, #0F0146);
  background-image: -o-linear-gradient(top, #0A012D, #0F0146);
  background-image: linear-gradient(to bottom, #0A012D, #0F0146);
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
  border-radius: 6px;
  color: lightcyan;
  padding: 10px 20px 10px 20px;
  border: solid #1A047E 2px;
  text-decoration: none;
}

.link {
  font-weight: lighter;
  color: #808080;
  font-size: 0.8em;
margin: 0 auto;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.biglink {
  font-size: 1em;
}

.btn:hover {
  background: #190879;
  background-image: -webkit-linear-gradient(top, #190879, #14025a);
  background-image: -moz-linear-gradient(top, #190879, #14025a);
  background-image: -ms-linear-gradient(top, #190879, #14025a);
  background-image: -o-linear-gradient(top, #190879, #14025a);
  background-image: linear-gradient(to bottom, #190879, #14025a);
  text-decoration: none;
}

.btn:disabled {
  background: #010101;
  color: #1A047E;
  cursor: not-allowed;
}


.container {
  padding: 2em;
}


.scroll {
  overflow: scroll!important;
  margin-bottom: 50px;
  height: 100vh;
}

</style>