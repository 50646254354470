<template>
    <div class="dimmer" v-if="show">
        <span class="line lowneon" ref="cline">{{currentLine}}</span>
    </div>
</template>

<script>

export default {
  name: "StoryLine",
  data() {
    return {
      show: false,
      lines: [],
      currentLine: "",
      sline: false
    }
  },
  methods: {
    showLines() {
      this.show = true;
      var currentLine = 0;
      var maxLines = this.lines.length;
      var timer1 = setInterval(()=>{
        if (currentLine == maxLines-1) {
          clearInterval(timer1);
          window.setTimeout(()=> { this.show = false;

            }, 10000);
        }
        this.currentLine = this.lines[currentLine];
        this.unfade(this.$refs.cline);
        window.setTimeout(()=>{ this.fade(this.$refs.cline); }, 6666);
        currentLine++;
      }, 8000);
    },
    fade(element) {
      var op = 1;  // initial opacity
      var timer = setInterval(function () {
        if (op <= 0.1){
          clearInterval(timer);
          element.style.display = 'none';
        }
        element.style.opacity = op;
        element.style.filter = 'alpha(opacity=' + op * 100 + ")";
        op -= 0.05;
      }, 50);
    },
    unfade(element) {
      var op = 0;  // initial opacity
      element.style.display = 'block';
      var timer = setInterval(function () {
        if (op >= 1){
          clearInterval(timer);
        }
        element.style.opacity = op;
        element.style.filter = 'alpha(opacity=' + op * 100 + ")";
        op += 0.05;
      }, 50);
    }
  }
}
</script>

<style scoped>

.dimmer {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #0f1114;
  z-index: 10000;
  text-align: center;
}

.line {
  color: ghostwhite;
  text-align: center;
  font-size: 2em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  position: absolute;
  opacity: 0;
}

.lowneon {
  color: #fff;
  text-shadow:
      0 0 0px #0ff,
      0 0 5px #0ff,
      0 0 10px #0D003F;
}

</style>