import api from '@/api';


function initInventory() {
    var lastUpdate = localStorage.getItem("updated");
    var currentTime = Math.floor(Date.now() / 1000);
    if (lastUpdate < currentTime - 2 * 60) {
       requestInventory();
    }
}

function requestInventory() {
    api.getData("inventory", null, updateInventoryFromServer);
}

function updateInventoryFromServer(errorstate, response) {
    if (errorstate == null) {
        if (response.status == 200 && response.data.energy>-1) {
            localStorage.setItem("energy", response.data.energy);
            localStorage.setItem("icecream", response.data.icecream);
            var currentTime = Math.floor(Date.now() / 1000);
            localStorage.setItem("updated", currentTime);
        }
    }
    dispatchEvent();
}

function changeEnergy(amount) {
    localStorage.setItem("energy", parseInt(localStorage.getItem("energy")) + amount);
    dispatchEvent();
}

function changeIcecream(amount) {
    localStorage.setItem("icecream", parseInt(localStorage.getItem("icecream")) + amount);
    dispatchEvent();
}

function dispatchEvent() {
    window.dispatchEvent(new CustomEvent('inventory-changed', { }));
}

function getEnergy() {
    return parseInt(localStorage.energy) || 0;
}

function getIcecream() {
    return parseInt(localStorage.icecream) || 0;
}

export default { changeEnergy, changeIcecream, initInventory, getEnergy, getIcecream }