import api from "../api"

function checkTokenLocally() {
    var cookieTokenContent = "";
    var storageTokenContent = "";
    var match = document.cookie.match(new RegExp('(^| )token=([^;]+)'));
    if (match) cookieTokenContent = match[2];
    if (localStorage.getItem("token")) storageTokenContent = localStorage.getItem("token");
    if (cookieTokenContent != "" && storageTokenContent != "" && cookieTokenContent === storageTokenContent) { // consider logged in
        return true;
    }
    forceLogout(true);
    return false;
}

var state = {
    logged: checkTokenLocally(),
    token: 'NONE',
    playerName: 'Unknown player'
}

var callback;



function loginViaApi(code, cb) {
    var loginData = { code: code };
    callback = cb;
    api.postData("login", loginData, parseReplyFromApi);
}

function parseReplyFromApi(errorstate, content) {
    if (errorstate!==null)
        return callback(errorstate);

    if (content.status === 200) {
        var contentData = content.data;
        var tokenExists = Object.prototype.hasOwnProperty.call(contentData,'token');

        if (tokenExists) {
            state.token = content.data.token;
            state.playerName = content.data.username;
            state.logged = true;
            var cookieExpiration = new Date();
            cookieExpiration.setDate(cookieExpiration.getDate() + 14);
            document.cookie = "token=" + state.token + "; expires=" + cookieExpiration.toUTCString() + "; path=/";
            localStorage.setItem("token", state.token);
            localStorage.setItem("name", state.playerName);
            localStorage.setItem("token_expiration", cookieExpiration.toISOString());
            return callback(state);
        }
    }
    return callback(state);

}

function createViaApi(email, nick, cb) {
    var userData = { email: email, nick: nick };
    callback = cb;
    api.postData("create", userData, parseReplyFromCreateApi);
}

function parseReplyFromCreateApi(errorstate, content) {
    if (errorstate!==null)
        return callback(false);

    if (content.status === 200)
       return callback(content.data.c);

    return callback(false);

}


function forceLogout(preventBlocking=false) {
    localStorage.clear();
    document.cookie.split(";").forEach(function (c) {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    if (!preventBlocking) window.location = "/";
    return preventBlocking;
}

export default { state, loginViaApi, forceLogout, createViaApi }