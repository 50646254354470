<template>
  <div class="container">
    <GameBoard v-bind:seed=seed v-bind:player=playerData></GameBoard>
    <Panel v-bind:player=playerData v-bind:playerName=playerName v-bind:inGame=inGame></Panel>
  </div>
</template>

<script>

import Panel from "../components/Panel";
import Player from "../obj/Player";
import GameBoard from "../components/GameBoard";

export default {
  name: 'Game',
  components: {
    Panel, GameBoard
  },
  methods: {

  },
  data() {
    return {
      playerData: Player,
      playerName: '',
      inGame: true,
      seed: ""
    }
  },
  mounted() {
    this.seed = this.$route.params.seed;

    if (localStorage.name) {
      this.playerName = localStorage.name;
    }
  },
  watch: {
    playerName(newName) {
      localStorage.name = newName;
    }
  }
}
</script>
