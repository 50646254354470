import axios from "axios";
import auth from "../auth"

//const apiBase = "http://localhost:8081/"
const defaultToken = "0";
const apiBase = "https://test-api.duuuuu.ch/"


function postData( route, data, callback ) {
    const error  = 'There was a problem posting data';

    var currentToken = defaultToken;
    if (localStorage.getItem("token") !== null) {
        currentToken = localStorage.getItem("token")
    }
    var rand = Math.floor(Math.random() * 10000);

    axios.post( apiBase+route+"?r="+rand, data, {
        withCredentials: true,
        headers: {'Authorization': 'Basic ' + currentToken }
        } ).then( res => {
        if (res.status !== 200) return callback(error+ ": HTTP "+ res.status +" " + res.statusText);
        return callback( null, res );
    })
        .catch( e => {
            if (e.response) {
                if (e.response.status == 401) {
                    auth.forceLogout();
                }
            }
            return callback( error+": " + String( e.message || '' ), [] );
        });
}

function getData( route, data, callback ) {
    const error  = 'There was a problem getting data';

    var currentToken = defaultToken;
    if (localStorage.getItem("token") !== null) {
        currentToken = localStorage.getItem("token")
    }

    var rand = Math.floor(Math.random() * 10000);

    // prevent caching of get requests
    axios.defaults.headers.common['Cache-Control'] = 'no-cache';
    axios.get( apiBase+route+"?r="+rand,
        {
            params: data,

            withCredentials: true,
            headers: {'Authorization': 'Basic ' + currentToken }
        } ).then( res => {
        if (res.status !== 200) return callback(error+ ": HTTP "+ res.status +" " + res.statusText);
        return callback( null, res );
    })
        .catch( e => {
            if (e.response) {
                if (e.response.status == 401) {
                    auth.forceLogout();
                }
            }
            return callback( error+": " + String( e.message || '' ), [] );
        });
}
export default { postData, getData }
