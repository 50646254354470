<template>
  <Menu ref="menu"></Menu>
  <div v-on:click="toggleMenu" id="menuToggler"></div>
  <div class="explorePanel" v-show="!inGame"><div class="explore"><span class="noto action-button"  v-on:click="explore">🔍</span></div></div>
  <div class="explorePanel" v-show="inGame"><div class="explore"><span class="noto action-button"  v-on:click="closegame">❌</span></div></div>
  <div class="bottom-panel">
    <span class="left"><span class="noto">👤</span> {{playerName}}</span> <span class="right"><span class="noto">⚡</span> {{energy}} <span class="noto">🍦</span> {{icecream}}</span>
  </div>
</template>

<script>



//import {setPosition} from "@/obj/Position";
import Menu from "./Menu";

export default {
  name: "Panel",
  props: ['player','inGame','position','playerName'],
  emits: ["exploreCell"],
  components: {
    Menu
  },
  data() {
    return {
      energy: 0,
      icecream: 0
    }
  },
  methods: {
    explore() {
      this.$emit('exploreCell', this.position.cell);
    },
    closegame() {
      this.$router.push({path:"/"});
    },
    toggleMenu() {
      this.$refs.menu.show = !this.$refs.menu.show;
    }
  },
  mounted() {
    window.addEventListener('inventory-changed', ()=>{
      this.energy = localStorage.energy;
      this.icecream = localStorage.icecream;
    });

    if (localStorage.energy) {
      this.energy = localStorage.energy;
    }
    if (localStorage.icecream) {
      this.icecream = localStorage.icecream;
    }
  },
  watch: {
    energy(newEnergy) {
      localStorage.energy = newEnergy;
    },
    icecream(newIcecream) {
      localStorage.icecream = newIcecream;
    }
  },
  created() {
    this.player.initInventory();
  },
}
</script>

<style scoped>

.bottom-panel {
  position: fixed;
  bottom: 0;
  background: #0D003F;
  width: 100%;
  text-align: center;
  padding: 8px 0;
  color: #0088F8;
  z-index: 10;
}

.left {
  float: left;
  margin-left: 0.5em;
}

.right {
  float: right;
  margin-right: 1em;
}

.explore {
  margin: 0 auto;
  display: block;
  background: #0A012D;
  left: 47%;
  border-radius: 50%;
  z-index: 999;
  color: #0088F8;
  width: 64px;
  height: 64px;
  font-size: 32px;
  border: 5px solid #0f1114;
  cursor: pointer;
}

.visited {
  background: #181818;
  color: #252525;
}

.explorePanel {
  position: fixed;
  bottom: 8px;
  width: 100%;
  text-align: center;
  z-index: 999;
}

.action-button {
  padding-top: 16px;
  position: relative;
  top: 4px;
}

#menuToggler {
  display: inline-block;
  width: 15%;
  height: 5%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;
}
</style>