<template>
  <transition name="fade" :duration="{ enter: 200, leave: 500 }">
    <div v-if="show" id="noenergy" >
      <div>
        <div class="upper"><span class="noto">🚫</span></div>
        <div class="gain redneon"><span class="noto">⚡</span></div>
        </div>
    </div>
  </transition>
</template>

<script>


export default {
  name: "NoEnergy",
  data() {
    return {
      show: false
    }
  },
  methods: {
    showNoEnergy() {
      this.show=true;
      setTimeout(function () { this.hideNoEnergy() }.bind(this), 1000)
    },
    hideNoEnergy() {
      this.show = false;
    }

  }
}
</script>

<style scoped>

#noenergy {
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 20%;
  left: 0;
}

.upper {
  z-index:99999;
  color: rgba(248, 248, 255, 0.50);
  font-size: 300%;
  position:absolute;
  display:inline-block;
}

.gain {
  display: inline;
  text-align: center;
  font-size: 3em;
  color: orangered;

}

.redneon {
  text-shadow:
      0 0 5px #F90000,
      0 0 10px #F90000,
      0 0 20px #F90000,
      0 0 40px #ff9393,
      0 0 80px #ff9393,
      0 0 90px #ff9393,
      0 0 100px #00f,
      0 0 150px #00f;
}


h1 {
  font-size: 666%;
  margin: 0;
  margin-top:-0.7em;
  width: 100%;
  display:block;
}

.front {
  z-index: 99999;
}

</style>