<template>
  <router-view/>
</template>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');

@font-face {
  font-family: 'Noto Emoji';
  src: url('/assets/NotoEmoji.woff2') format('woff2'),
  url('/assets/NotoEmoji.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  padding: 0;
  background: #030011;
  font-family: 'Oswald', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1A047E;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.noto {
  font-family: 'Noto Emoji';
  font-weight: normal;
  font-style: normal;
}

</style>
<script>

</script>