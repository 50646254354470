<template>
    <div class="dimmer" v-if="show" v-on:click="show=false"></div>
    <div v-if="show" id="menu" class="front">
      <div class="menu-content">
        <!--<div class="menu-button"><span class="noto big">🔕</span><span class="title">Vypnúť zvuky</span></div>
        <div class="menu-button"><span class="noto big">🔊</span><span class="title">Zapnúť hudbu</span></div>
        <div class="menu-button"><span class="noto big">❓</span><span class="title">Pomocník</span></div> -->
        <div class="menu-button" v-on:click="reload"><span class="noto  big">♻</span><span class="title">Znovu načítať</span></div>
        <div class="menu-button" v-on:click="logout"><span class="noto  big">⛔</span><span class="title">Odhlásiť sa</span></div>
      </div>
    </div>
</template>

<script>

import auth from "../auth";

export default {
  name: "Menu",
  data() {
    return {
      show: false
    }
  },
  methods: {
    logout() {
      auth.forceLogout();
    },
    reload() {

      location.reload(true);
    }

  }
}
</script>

<style scoped>

.dimmer {
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(0,0,0,0.5);
  z-index: 10000;
}

#menu {
  display: block;
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 50%;
  background: #010101;
  color: ghostwhite;
  border-radius: 15px;
}

.front {
  z-index: 99999;
}

.menu-content {
  width: 100%;
  margin-top: 12px;
  text-align: center;
}

.menu-button {
  width: 25%;
  height: 25%;
  border: 2px ghostwhite solid;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  padding: 2%;
}

.menu-button:hover {
  background: #0A012D;
  cursor: pointer;
  text-shadow:
      0 0 5px #fff,
      0 0 10px #fff,
      0 0 20px #fff,
      0 0 40px #0ff,
      0 0 80px #0ff,
      0 0 90px #0Ff,
      0 0 100px #00f,
      0 0 150px #00f;
}

.menu-button .big {
  font-size: 200%;
}

.menu-button .title {
  margin-top: 12px;
  display: block;
}
</style>