<template>
  <div class="container" >
    <div class="card">
      <h1 class="neon">&#128123;</h1>
      <h2 class="neon">Získaj kód</h2>
      <p>Zadaj svoju platnú e-mailovú adresu a prezývku, ktorou budeš vystupovať v hre.</p>
      <input :disabled="validating || error" v-model="nick" type="text" name="nick" class="field" placeholder="Tvoja prezývka" v-on:keypress="enter" :class="{'shake':error}"><br>
      <input :disabled="validating || error" v-model="email" type="text" name="email" class="field" placeholder="Zadaj e-mailovú adresu" v-on:keypress="enter" :class="{'shake':error}">
      <button :disabled="(email.length < 9) || (nick.length < 3) || (validating) || (error)" class="btn" v-on:click="login">Vytvoriť kód</button>
      <p><small>Na uvedenú adresu bude doručený prístupový kód s ďalšími pokynmi. Doručenie môže trvať niekoľko minút. Prosím, skontroluj aj priečinok so spamom. Nikdy nevieš...</small></p>
    </div>
  </div>
  <canvas class="background"></canvas>
</template>

<script>
import Particles from 'particlesjs';
import auth from "../auth/";
import router from "../router";

export default {
  name: "Create",
  data() {
    return {
      email: '',
      nick: '',
      validating: false,
      error: false,
      particles: null,
      sent: false
    }
  },
  mounted() {
    this.particles = Particles.init({
      selector: ".background",
      color: "#06001D",
      speed: 0.02,
      maxParticles: 100,
      connectParticles: false,
      sizeVariations: 10
    })
  },
  methods: {
    login: function() {
      this.error = false;
      this.validating = true;
      auth.createViaApi(this.email, this.nick, this.feedback);
    },
    feedback: function(state) {
      this.validating = false;
      if (state === true) {
        router.push("/");
      } else {
        this.error = true;
        setTimeout(()=> {
          this.error = false;
          this.nick = "";
          this.email = "";
        }, 820);
      }
    },
    enter: function(e) {
      this.error = false;
      if ((this.email.length > 4) && !(this.validating) && (e.keyCode === 13)) {
        this.login();
      }
    }
  },
  beforeUnmount() {
    if (this.particles !== null)
      this.particles.destroy();
  }
}
</script>

<style lang="scss" scoped>


.background {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: -1;
}


.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  color: #F90000!important;
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.center {
  text-align: center;
}

input.field {
  background: $color_dark;
  color: white;
  border: 5px solid $color_dark;
  font-family: $font_family_head;
  text-transform: uppercase;
  margin-top: 2em;
  text-align: center;
  display: block;
  width: 80%;
  margin: 0 auto;
}

h2 {
  font-size:2em;
}

h3 { color: #0088F8; }

h1 {
  font-size: 666%;
  margin: 0;
  margin-top:-0.7em;
  width: 100%;
  display:block;
}

.ghosttext {
  color: ghostwhite;
}
.btn {
  display: block;
  margin: 0 auto;
  color: lightcyan;
  padding: 10px 20px;
  margin-top: 1em;
  font-family: $font_family_head;
  text-transform: uppercase;
  cursor: pointer;
  background: #0A012D;
  background-image: -webkit-linear-gradient(top, #0A012D, #0F0146);
  background-image: -moz-linear-gradient(top, #0A012D, #0F0146);
  background-image: -ms-linear-gradient(top, #0A012D, #0F0146);
  background-image: -o-linear-gradient(top, #0A012D, #0F0146);
  background-image: linear-gradient(to bottom, #0A012D, #0F0146);
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
  border-radius: 6px;
  color: lightcyan;
  padding: 10px 20px 10px 20px;
  border: solid #1A047E 2px;
  text-decoration: none;
}

.link {
  font-weight: lighter;
  color: #808080;
  font-size: 0.8em;
  margin: 0 auto;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.btn:hover {
  background: #190879;
  background-image: -webkit-linear-gradient(top, #190879, #14025a);
  background-image: -moz-linear-gradient(top, #190879, #14025a);
  background-image: -ms-linear-gradient(top, #190879, #14025a);
  background-image: -o-linear-gradient(top, #190879, #14025a);
  background-image: linear-gradient(to bottom, #190879, #14025a);
  text-decoration: none;
}

.btn:disabled {
  background: #010101;
  color: #1A047E;
  cursor: not-allowed;
}


.container {
  padding: 2em;
}


</style>