import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home'
import Login from "../views/Login";
import Game from "../views/Game"
import auth from "../auth";
import Create from "../views/Create";

const routes = [
  {
    path: '/auth',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Main',
    component: Home
  },

  {
    path: '/game/:seed',
    name: 'Game',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Game
  },
  {
    path: '/create',
    name: 'Create',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Create
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {

  if ((to.name !== 'Login' && to.name !== "Create") && !auth.state.logged) {
    next({ name: 'Login' })
  } else {
    next();
  }
})

export default router
