<template>
  <transition name="fade" :duration="{ enter: 500, leave: 1000 }">
    <div v-if="show" id="gained" >
      <!--<h1 class="neon front">&#128123;</h1>//-->
      <div class="gain neon">+<span class="count">{{count}}</span><span class="noto">{{item}}</span></div>
    </div>
  </transition>
</template>

<script>


export default {
  name: "ItemGained",
  data() {
    return {
      item: "⚡",
      count: 0,
      show: false
    }
  },
  methods: {
      showGain() {
        this.show=true;
        setTimeout(function () { this.hideGain() }.bind(this), 2000)
      },
    hideGain() {
        this.show = false;
    }

  }
}
</script>

<style scoped>

#gained {
 width: 100%;
  text-align: center;
  position: fixed;
  top: 10%;
  left: 0;
}

.gain {
  display: inline;
  text-align: center;
  font-size: 3em;
  color: ghostwhite;

}


h1 {
  font-size: 666%;
  margin: 0;
  margin-top:-0.7em;
  width: 100%;
  display:block;
}

.front {
  z-index: 99999;
}

</style>